/* eslint-disable react/display-name */
import { Box, SystemProps } from "flicket-ui";
import React, { forwardRef } from "react";
import styled from "styled-components";

import req from "./req";

const StyledIcon = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  svg {
    vertical-align: middle;
  }
`;

export type IconNames =
  | "accessibility_purple"
  | "accessibility"
  | "account"
  | "admin-panel"
  | "alcohol-free"
  | "arrow-dropdown"
  | "assignment"
  | "backspace"
  | "calendar-today"
  | "check-circle"
  | "check"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "close"
  | "compare"
  | "date-range"
  | "dollar"
  | "double-chevron-left"
  | "double-chevron-right"
  | "email"
  | "error"
  | "event"
  | "facebook"
  | "facebook-event"
  | "fb-like"
  | "filter"
  | "google"
  | "help"
  | "info"
  | "instagram"
  | "laybuy"
  | "laybuy_sunglasses"
  | "laybuy_lb_heart"
  | "laybuy_cursor"
  | "laybuy_calendar"
  | "logout"
  | "map"
  | "membership"
  | "menu"
  | "minus"
  | "more"
  | "my-account"
  | "my-tickets"
  | "print"
  | "plus"
  | "r18"
  | "radio-checked"
  | "radio"
  | "remove-circle"
  | "search"
  | "share"
  | "shopping-cart"
  | "support"
  | "tiktok"
  | "twitter"
  | "user-account"
  | "zoom-in"
  | "person-arms-spread"
  | "map-pin"
  | "calendar-blank";

export interface IconProps
  extends SystemProps,
    Omit<Omit<React.HTMLAttributes<HTMLDivElement>, "color">, "css"> {
  icon: IconNames;
  as?: string;
  href?: string;
  className?: string;
  onClick?: any;
}

export const Icon = forwardRef(({ icon, color, ...props }: IconProps, ref) => {
  function getAs(): any {
    switch (true) {
      case props.as !== undefined:
        return props.as;
      case props.href !== undefined:
        return "a";
      case props.onClick !== undefined:
        return "button";
      default:
        return "div";
    }
  }

  const IconSvg = req(`./${icon}.svg`);

  return (
    <StyledIcon ref={ref} aria-hidden as={getAs()} color={color} {...props}>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <IconSvg.default />
    </StyledIcon>
  );
});
